<template>
  <div class="ChartDisplay">
    <pie-chart
        v-if="voting && loaded"
        :chartdata="chartdata"
        :options="options" />
  </div>
</template>

<script>
import PieChart from './PieChart'

export default {
  name: 'ChartDisplay',
  props: {
    voting: Object
  },
  components: { PieChart },
  computed: {
    chartdata() {
      let voting = this.voting
      return {
        labels: ['A', 'B', 'C', 'D'],
        datasets: [{
          label: '',
          data: [voting.votes.A, voting.votes.B, voting.votes.C, voting.votes.D],
          backgroundColor: [
            'red',
            'green',
            'blue',
            'yellow'
          ],
        }]
      }
    }
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
  },
  data: () => ({
    loaded: false,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      circumference: Math.PI,
      spacing: 20,
      rotation: -Math.PI
    }
  }),
  async mounted () {
    this.loaded = false
    setTimeout( () => {
      this.loaded = true
    }, 500, this)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
