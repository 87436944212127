<template>
  <div class="chartdisplay">
    <ChartDisplay v-if="voting && voting.visible" :voting="voting" />
    <CodeDisplay v-if="voting" :voting="voting" />
  </div>
</template>

<script>
import ChartDisplay from '@/components/ChartDisplay.vue'
import CodeDisplay from '@/components/CodeDisplay.vue'

import VotingDataService from '@/services/VotingDataService.js'

export default {
  name: 'Chart',
  components: {
    ChartDisplay,
    CodeDisplay
  },
  data() {
    return {
      voting: null
    };
  },
  computed: {
  }, 
  methods: {
    onDataChange(items) {
      if(items && items.id) {
        this.voting = items;
      }
    },
  },
  async mounted() {
    if(this.$route.params.votingid) {
      console.log('load voting: ' + this.$route.params.votingid)
      this.voting = await VotingDataService.get(this.$route.params.votingid)
      if(!this.voting) {
        console.log('empty voting: ' + this.$route.params.votingid)
        this.$router.push('/chart')
      } else {
        VotingDataService.onValue(this.$route.params.votingid, this.onDataChange);
      }
    } else {
      // create new voting and redirect
      let newVoting = VotingDataService.new()
      let votingID = await VotingDataService.create(newVoting)
      console.log('create new voting: ' + votingID)
      this.$router.push('/chart/' + votingID)
    }
  },
  beforeDestroy() {
    // VotingDataService.offValue(this.onDataChange);
  }
}
</script>
