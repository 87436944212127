import { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,
  props: {
    chartdata: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  computed: {
    data: function() {
      return this.chartdata;
    },
  },  
  mounted () {
    this.renderPieChart()
  },
  methods: {
    renderPieChart: function() {
      this.renderChart(
        this.data,
        this.options
      );      
    }
  },
  watch: {
    chartdata: function() {
      this.renderPieChart();
    }
  }
}