<template>
  <div class="CodeDisplay">
    <div v-html="qrCode"></div>
  </div>
</template>

<script>
import { BrowserQRCodeSvgWriter } from '@zxing/browser'

const codeWriter = new BrowserQRCodeSvgWriter()

export default {
  name: 'CodeDisplay',
   data () {
    return {
      isLoading: true,
      qrCode: ''
    }
  },
  props: {
    voting: Object,
  },
  computed: {
    votingId: function() {
      if(this.voting && this.voting.id) {
        return this.voting.id
      } else {
        return 'schoolars'
      }
    },
    // qrCode: function() {
    //   return codeWriter.write(this.voting.id, 200, 200).outerHTML
    // }
  },
  methods: {
    renderQRCode: function() {
        this.qrCode =codeWriter.write(this.votingId, 200, 200).outerHTML
    }
  },
  watch: {
    voting: function() {
      this.renderQRCode()
    }
  },
  mounted () {
    // this.renderQRCode();
    this.isLoading = false;
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
